// React And Router
import * as React from "react";
import { Routes, Route, Link} from "react-router-dom";

// CSS Import
import './App.css';

// Component Import
import Home from './components/Home/home';
import About from './components/About/about';
import Contact from './components/Contact/contact';
// import Services from './components/Services/services';
import Blogs from './components/Blogs/blogs';
import Auth from './components/Auth/auth';

//Material Design Components
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
// import CottageIcon from '@mui/icons-material/Cottage';
// import WorkspacesIcon from '@mui/icons-material/Workspaces';
// import GroupsIcon from '@mui/icons-material/Groups';
// import EmailIcon from '@mui/icons-material/Email';

function App() {
  const [state, setState] = React.useState({
    left: false
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  // SideNav List
  const list = (anchor) => (
    <Box
      sx={{ width: '100vw'}}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      style={{ backgroundColor: 'light-gray'}}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List style={{ textAlign: 'center'}}>
        <h1>Kelaiah</h1>
        <hr/>
        <ListItem button><Link style={{ margin: '10px auto 10px auto', fontSize: '21px'}} to="/" className="sideNavButton">Home</Link></ListItem>
        <ListItem button><Link style={{ margin: '10px auto 10px auto', fontSize: '21px'}} to="/blogs" className="sideNavButton">Blogs</Link></ListItem>
        {/* <ListItem button><Link style={{ margin: '10px auto 10px auto', fontSize: '21px'}} to="/services" className="sideNavButton">Services</Link></ListItem> */}
        <ListItem button><Link style={{ margin: '10px auto 10px auto', fontSize: '21px'}} to="/about" className="sideNavButton">About Us</Link></ListItem>
        <ListItem button><Link style={{ margin: '10px auto 10px auto', fontSize: '21px'}} to="/contact" className="sideNavButton">Contact Us</Link></ListItem>
        <hr/>
        <button onClick={toggleDrawer(anchor, false)}><CloseIcon/></button>
      </List>
    </Box>
  );

  return (
      <div className="App">
        <nav className="App-header" style={{position: 'fixed', width: '100%'}}>
            <button className="sideNav nav-button" onClick={toggleDrawer('left', true)}><MenuIcon/></button>
            <Link to="/" className="App-title">Kelaiah</Link>
            <div className="primary-nav">
              {/* <Link to="/services" className="nav-button">Services</Link> */}
              <Link to="/blogs" className="nav-button">Blogs</Link>
              <Link to="/about" className="nav-button">About Us</Link>
              <Link to="/contact" className="nav-button">Contact Us</Link>
            </div>
        </nav>

        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            {/* <Route path="services" element={<Services />} /> */}
            <Route path="blogs" element={<Blogs/>} />
            <Route path="contact" element={<Contact />} />
            <Route path="auth" element={<Auth/>} />
            <Route path="*" element={<Home/>} />
        </Routes>
        <SwipeableDrawer
            anchor={'left'}
            open={state['left']}
            onClose={toggleDrawer('left', false)}
            onOpen={toggleDrawer('left', true)}
          >
            {list('left')}
        </SwipeableDrawer>
      </div>
  );
}

export default App;
