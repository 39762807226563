import * as React from "react";

import '../Home/home.css';

import {Link} from "react-router-dom";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function Blogs() {
    return (
      <>
        <ScrollToTopOnMount />
        <main className="topSection">
          <h2>Blogs</h2>
          <p>
            We are working on this page. Please wait
          </p>
        </main>

        {/* Call To Action */}
        <section className="sectionMedium">
          <div className="container text-center">
              <h1>Contact Us Today</h1>
              <h3>Want to help us achieve our goals.</h3>
              <button>Message Us</button>
          </div> 
        </section>

        {/* Footer */}
        <section className="sectionSmall themebackground overlayText">
          <h1>Connect With Us</h1>
          <div className="containerFlex">
            <div className="flex-item">
              <h3>Contact Us</h3>
              <p>Email: kelaiah.care@gmail.com</p>
            </div>
            <div className="flex-item">
              <h3>Our Pages</h3>
              <p><Link className="sideNavLink" to="/services">Services</Link></p>
              <p><Link className="sideNavLink" to="/about">About Us</Link></p>
              <p><Link className="sideNavLink" to="/contact">Contact Us</Link></p>
              <p>log-in</p>
            </div>
            <div className="flex-item">
              <h3>Hours Of Operation</h3>
              <p>Mon - Fri: 9:00AM - 6:00PM</p>
              <p>Sat: 10:00AM - 5:00PM</p>
              <p>Contact Us Any Time</p>
            </div>
          </div>
        </section>
      </>
    );
}

export default Blogs;