import * as React from "react";
import '../Home/home.css';
// import AlertDialog from "../dialog"
import {db} from '../../util/firebase';

import { doc, setDoc, collection} from "firebase/firestore";

import {Link} from "react-router-dom";
import { useEffect } from "react";
import { TextField } from "@mui/material";



function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function ShowContactForm() {

  const [newContactForm, setNewContactForm] = React.useState({
    firstName: '',
    lastName: '',
    comments: '',
    email: '',
  });

  const updateField = e => {
    setNewContactForm({
      ...newContactForm,
      [e.target.name]: e.target.value
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if(newContactForm) {
      // Add a new document with a generated id
      const newData = doc(collection(db, "ContactForm"));
      const insert = await setDoc(newData, newContactForm);

      if (!insert) {
        setNewContactForm({
          firstName: '',
          lastName: '',
          comments: '',
          email: '',
        });
        alert('Your Information was submitted');
      }
      else {
        alert('There was a problem');
      }
    }
  };

  return(
    <>
      {/* Contact Form */}
      <section className="sectionLarge topSection">
          <div className="containerFlex">
            <div className="flex-item" style={{width: '100%'}}>
              <h2>Contact Us Today</h2>
              <span><p>We are working on this page. Please wait, or email us at kelaiah.care@gmail.com</p></span>
            </div>
            <div className="flex-item" style={{width: '100%', borderRadius: '24px', backgroundColor: 'lightgray'}}>
            <form onSubmit={onSubmit}>
              <h2>Contact Form</h2>
              <TextField style={{marginTop: '12px'}} id="outlined-basic" label="First Name" variant="outlined" value={newContactForm.firstName} name="firstName" onChange={updateField} fullWidth required/>
              <TextField style={{marginTop: '12px'}} id="outlined-basic" label="Last Name" variant="outlined" value={newContactForm.lastName} name="lastName" onChange={updateField} fullWidth required/>
              <TextField style={{marginTop: '12px'}} id="outlined-basic" label="Comments" variant="outlined" value={newContactForm.comments} name="comments" onChange={updateField} fullWidth required/>
              <TextField style={{marginTop: '12px'}} id="outlined-basic" label="Email" variant="outlined" value={newContactForm.email} name="email" type="email" autoComplete="email" onChange={updateField} fullWidth required/>
              <br />
              <button style={{marginTop: '12px'}} className="button-main">Submit</button>
            </form>
            </div>
          </div>
        </section>
    </>
  );
}


function Contact() {
    return (
      <>
        <ScrollToTopOnMount />
        <main>
          {/* Hero Banner */}
          <section className="hero-image overlayText sectionLarge topSection">
            <hr/>
            <h1>Contact Us Today</h1>
            <span><h3>We are always trying to improve and find better ways on spreading blessings to others around the world</h3></span>
            <button className="button-overlay">Support Us</button>
            <hr/>
          </section>
          
          <ShowContactForm />

        </main>

        {/* Footer */}
        <section className="sectionSmall themebackground overlayText">
          <h1>Connect With Us</h1>
          <div className="containerFlex">
            <div className="flex-item">
              <h3>Contact Us</h3>
              <p>Email: kelaiah.care@gmail.com</p>
            </div>
            <div className="flex-item">
              <h3>Our Pages</h3>
              <p><Link className="sideNavLink" to="/services">Services</Link></p>
              <p><Link className="sideNavLink" to="/about">About Us</Link></p>
              <p><Link className="sideNavLink" to="/contact">Contact Us</Link></p>
              <p><Link className="sideNavLink" to="/auth">Sign In</Link></p>
            </div>
            <div className="flex-item">
              <h3>Hours Of Operation</h3>
              <p>Mon - Fri: 9:00AM - 6:00PM</p>
              <p>Sat: 10:00AM - 5:00PM</p>
              <p>Contact Us Any Time</p>
            </div>
          </div>
        </section>
      </>
    );
}

export default Contact;