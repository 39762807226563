import * as React from "react";

import '../Home/home.css';

import {Link} from "react-router-dom";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}


function About() {
    return (
      <>
        <ScrollToTopOnMount />

        {/* Hero Section */}
        <section className="hero-image overlayText sectionLarge topSection">
            <hr/>
            <h1>About Us</h1>
            <span><h3>View our mission, our purpose, and our plan</h3></span>
            <button className="button-overlay">Support Us</button>
            <hr/>
        </section>

        {/* About Section */}
        <section className="sectionSmall" style={{backgroundColor:'white'}}>
          <div className="containerFlex">
            <div className="flex-item">
                <h2>How We Started</h2>
            </div>
            <div className="flex-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque volutpat ac tincidunt vitae semper quis lectus nulla. At lectus urna duis convallis convallis tellus id interdum velit. Iaculis eu non diam phasellus. Cursus vitae congue mauris rhoncus aenean vel. Enim ut sem viverra aliquet eget sit amet tellus. Tellus in metus vulputate eu scelerisque felis. Nisl pretium fusce id velit ut tortor pretium. Congue quisque egestas diam in. Aliquet eget sit amet tellus. Pretium aenean pharetra magna ac placerat. Cursus in hac habitasse platea dictumst. Porttitor eget dolor morbi non arcu. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Adipiscing bibendum est ultricies integer quis auctor elit sed vulputate.

Velit sed ullamcorper morbi tincidunt ornare. Enim blandit volutpat maecenas volutpat blandit. Gravida arcu ac tortor dignissim convallis. Tristique senectus et netus et malesuada fames ac. Neque convallis a cras semper auctor neque vitae. Viverra nibh cras pulvinar mattis. In pellentesque massa placerat duis ultricies lacus. Tortor pretium viverra suspendisse potenti nullam. Quam quisque id diam vel quam. Risus nec feugiat in fermentum posuere urna nec tincidunt. Habitant morbi tristique senectus et netus et malesuada fames ac.

Fermentum odio eu feugiat pretium nibh ipsum consequat nisl vel. Fermentum et sollicitudin ac orci. Ut etiam sit amet nisl purus in. Proin nibh nisl condimentum id. Elementum integer enim neque volutpat ac. Dolor sed viverra ipsum nunc. Feugiat nisl pretium fusce id velit ut tortor pretium viverra. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Facilisis leo vel fringilla est ullamcorper eget nulla. Facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris. Tellus in metus vulputate eu scelerisque felis imperdiet proin.

Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Vulputate sapien nec sagittis aliquam. Nam libero justo laoreet sit amet. Nulla facilisi etiam dignissim diam quis. At consectetur lorem donec massa sapien faucibus. Vitae nunc sed velit dignissim. Duis convallis convallis tellus id interdum velit laoreet id donec. Purus non enim praesent elementum. A arcu cursus vitae congue mauris rhoncus. Pretium lectus quam id leo in vitae. Sagittis vitae et leo duis ut diam quam nulla. Facilisis magna etiam tempor orci eu lobortis elementum nibh. Sed vulputate mi sit amet mauris. Massa massa ultricies mi quis hendrerit dolor magna. Consequat semper viverra nam libero justo. Adipiscing vitae proin sagittis nisl rhoncus mattis. Non blandit massa enim nec dui nunc mattis enim ut. Eget duis at tellus at.

Purus ut faucibus pulvinar elementum. In eu mi bibendum neque egestas. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Donec massa sapien faucibus et molestie ac feugiat sed lectus. Habitant morbi tristique senectus et netus. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Aliquam ut porttitor leo a diam sollicitudin tempor id. Laoreet suspendisse interdum consectetur libero. Consectetur libero id faucibus nisl tincidunt eget nullam non nisi. Viverra ipsum nunc aliquet bibendum enim facilisis gravida. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Eget nunc scelerisque viverra mauris in aliquam sem fringilla ut. Pretium quam vulputate dignissim suspendisse in est ante in. Vel facilisis volutpat est velit egestas. Tristique nulla aliquet enim tortor at auctor. Quisque non tellus orci ac auctor augue mauris. Volutpat lacus laoreet non curabitur gravida arcu ac. Id cursus metus aliquam eleifend.</p>
            </div>
          </div>
          <div className="containerFlex">
            <div className="flex-item">
                <h2>Our Mission</h2>
            </div>
            <div className="flex-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque volutpat ac tincidunt vitae semper quis lectus nulla. At lectus urna duis convallis convallis tellus id interdum velit. Iaculis eu non diam phasellus. Cursus vitae congue mauris rhoncus aenean vel. Enim ut sem viverra aliquet eget sit amet tellus. Tellus in metus vulputate eu scelerisque felis. Nisl pretium fusce id velit ut tortor pretium. Congue quisque egestas diam in. Aliquet eget sit amet tellus. Pretium aenean pharetra magna ac placerat. Cursus in hac habitasse platea dictumst. Porttitor eget dolor morbi non arcu. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Adipiscing bibendum est ultricies integer quis auctor elit sed vulputate.

Velit sed ullamcorper morbi tincidunt ornare. Enim blandit volutpat maecenas volutpat blandit. Gravida arcu ac tortor dignissim convallis. Tristique senectus et netus et malesuada fames ac. Neque convallis a cras semper auctor neque vitae. Viverra nibh cras pulvinar mattis. In pellentesque massa placerat duis ultricies lacus. Tortor pretium viverra suspendisse potenti nullam. Quam quisque id diam vel quam. Risus nec feugiat in fermentum posuere urna nec tincidunt. Habitant morbi tristique senectus et netus et malesuada fames ac.

Fermentum odio eu feugiat pretium nibh ipsum consequat nisl vel. Fermentum et sollicitudin ac orci. Ut etiam sit amet nisl purus in. Proin nibh nisl condimentum id. Elementum integer enim neque volutpat ac. Dolor sed viverra ipsum nunc. Feugiat nisl pretium fusce id velit ut tortor pretium viverra. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Facilisis leo vel fringilla est ullamcorper eget nulla. Facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris. Tellus in metus vulputate eu scelerisque felis imperdiet proin.

Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Vulputate sapien nec sagittis aliquam. Nam libero justo laoreet sit amet. Nulla facilisi etiam dignissim diam quis. At consectetur lorem donec massa sapien faucibus. Vitae nunc sed velit dignissim. Duis convallis convallis tellus id interdum velit laoreet id donec. Purus non enim praesent elementum. A arcu cursus vitae congue mauris rhoncus. Pretium lectus quam id leo in vitae. Sagittis vitae et leo duis ut diam quam nulla. Facilisis magna etiam tempor orci eu lobortis elementum nibh. Sed vulputate mi sit amet mauris. Massa massa ultricies mi quis hendrerit dolor magna. Consequat semper viverra nam libero justo. Adipiscing vitae proin sagittis nisl rhoncus mattis. Non blandit massa enim nec dui nunc mattis enim ut. Eget duis at tellus at.

Purus ut faucibus pulvinar elementum. In eu mi bibendum neque egestas. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Donec massa sapien faucibus et molestie ac feugiat sed lectus. Habitant morbi tristique senectus et netus. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Aliquam ut porttitor leo a diam sollicitudin tempor id. Laoreet suspendisse interdum consectetur libero. Consectetur libero id faucibus nisl tincidunt eget nullam non nisi. Viverra ipsum nunc aliquet bibendum enim facilisis gravida. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Eget nunc scelerisque viverra mauris in aliquam sem fringilla ut. Pretium quam vulputate dignissim suspendisse in est ante in. Vel facilisis volutpat est velit egestas. Tristique nulla aliquet enim tortor at auctor. Quisque non tellus orci ac auctor augue mauris. Volutpat lacus laoreet non curabitur gravida arcu ac. Id cursus metus aliquam eleifend.</p>
            </div>
          </div>
          <div className="containerFlex">
            <div className="flex-item">
                <h2>Our Plan</h2>
            </div>
            <div className="flex-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Neque volutpat ac tincidunt vitae semper quis lectus nulla. At lectus urna duis convallis convallis tellus id interdum velit. Iaculis eu non diam phasellus. Cursus vitae congue mauris rhoncus aenean vel. Enim ut sem viverra aliquet eget sit amet tellus. Tellus in metus vulputate eu scelerisque felis. Nisl pretium fusce id velit ut tortor pretium. Congue quisque egestas diam in. Aliquet eget sit amet tellus. Pretium aenean pharetra magna ac placerat. Cursus in hac habitasse platea dictumst. Porttitor eget dolor morbi non arcu. Sed lectus vestibulum mattis ullamcorper velit sed ullamcorper morbi. Adipiscing bibendum est ultricies integer quis auctor elit sed vulputate.

Velit sed ullamcorper morbi tincidunt ornare. Enim blandit volutpat maecenas volutpat blandit. Gravida arcu ac tortor dignissim convallis. Tristique senectus et netus et malesuada fames ac. Neque convallis a cras semper auctor neque vitae. Viverra nibh cras pulvinar mattis. In pellentesque massa placerat duis ultricies lacus. Tortor pretium viverra suspendisse potenti nullam. Quam quisque id diam vel quam. Risus nec feugiat in fermentum posuere urna nec tincidunt. Habitant morbi tristique senectus et netus et malesuada fames ac.

Fermentum odio eu feugiat pretium nibh ipsum consequat nisl vel. Fermentum et sollicitudin ac orci. Ut etiam sit amet nisl purus in. Proin nibh nisl condimentum id. Elementum integer enim neque volutpat ac. Dolor sed viverra ipsum nunc. Feugiat nisl pretium fusce id velit ut tortor pretium viverra. Enim nunc faucibus a pellentesque sit amet porttitor eget dolor. Integer feugiat scelerisque varius morbi enim nunc faucibus a pellentesque. Facilisis leo vel fringilla est ullamcorper eget nulla. Facilisi nullam vehicula ipsum a arcu cursus vitae congue mauris. Tellus in metus vulputate eu scelerisque felis imperdiet proin.

Arcu vitae elementum curabitur vitae nunc sed velit dignissim. Proin fermentum leo vel orci porta non pulvinar neque laoreet. Vulputate sapien nec sagittis aliquam. Nam libero justo laoreet sit amet. Nulla facilisi etiam dignissim diam quis. At consectetur lorem donec massa sapien faucibus. Vitae nunc sed velit dignissim. Duis convallis convallis tellus id interdum velit laoreet id donec. Purus non enim praesent elementum. A arcu cursus vitae congue mauris rhoncus. Pretium lectus quam id leo in vitae. Sagittis vitae et leo duis ut diam quam nulla. Facilisis magna etiam tempor orci eu lobortis elementum nibh. Sed vulputate mi sit amet mauris. Massa massa ultricies mi quis hendrerit dolor magna. Consequat semper viverra nam libero justo. Adipiscing vitae proin sagittis nisl rhoncus mattis. Non blandit massa enim nec dui nunc mattis enim ut. Eget duis at tellus at.

Purus ut faucibus pulvinar elementum. In eu mi bibendum neque egestas. Dictumst vestibulum rhoncus est pellentesque elit ullamcorper dignissim cras tincidunt. Donec massa sapien faucibus et molestie ac feugiat sed lectus. Habitant morbi tristique senectus et netus. Cursus metus aliquam eleifend mi in nulla posuere sollicitudin aliquam. Aliquam ut porttitor leo a diam sollicitudin tempor id. Laoreet suspendisse interdum consectetur libero. Consectetur libero id faucibus nisl tincidunt eget nullam non nisi. Viverra ipsum nunc aliquet bibendum enim facilisis gravida. Consectetur lorem donec massa sapien faucibus et molestie ac feugiat. Eget nunc scelerisque viverra mauris in aliquam sem fringilla ut. Pretium quam vulputate dignissim suspendisse in est ante in. Vel facilisis volutpat est velit egestas. Tristique nulla aliquet enim tortor at auctor. Quisque non tellus orci ac auctor augue mauris. Volutpat lacus laoreet non curabitur gravida arcu ac. Id cursus metus aliquam eleifend.</p>
            </div>
          </div>
        </section>

        {/* Call To Action */}
        <section className="sectionMedium">
          <div className="container text-center">
              <h1>Contact Us Today</h1>
              <h3>Want to help us achieve our goals.</h3>
              <button>Message Us</button>
          </div> 
        </section>

        {/* Footer */}
        <section className="sectionSmall themebackground overlayText">
        <h1>Connect With Us</h1>
        <div className="containerFlex">
          <div className="flex-item">
            <h3>Contact Us</h3>
            <p>Email: kelaiah.care@gmail.com</p>
          </div>
          <div className="flex-item">
            <h3>Our Pages</h3>
            <p><Link className="sideNavLink" to="/services">Services</Link></p>
            <p><Link className="sideNavLink" to="/about">About Us</Link></p>
            <p><Link className="sideNavLink" to="/contact">Contact Us</Link></p>
            <p>log-in</p>
          </div>
          <div className="flex-item">
            <h3>Hours Of Operation</h3>
            <p>Mon - Fri: 9:00AM - 6:00PM</p>
            <p>Sat: 10:00AM - 5:00PM</p>
            <p>Contact Us Any Time</p>
          </div>
        </div>
      </section>
      </>
    );
  }

export default About;