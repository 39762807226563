import * as React from "react";
import '../Home/home.css';

import {Link} from "react-router-dom";
import { useEffect } from "react";

import {getAuth, signOut, onAuthStateChanged, signInWithRedirect, GoogleAuthProvider, getRedirectResult} from "firebase/auth";



function ScrollToTopOnMount() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return null;
}


function Auth() {
    const [user, setUser] = React.useState(null);
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const signInRedirect = () => signInWithRedirect(auth, provider);

    useEffect(() => {
        getRedirectResult(auth)
        .then((result) => {
            // This gives you a Google Access Token. You can use it to access Google APIs.
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const token = credential.accessToken;

            // The signed-in user info.
            const returnedUser = result.user;
            setUser(returnedUser);
            console.log(user)
            // setUser = result.user;
            console.log('Here is the user');
            console.log(returnedUser);
        }).catch((error) => {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;

            // setUser = null;
            console.log(errorCode);
            console.log(errorMessage);
            // The email of the user's account used.
            const email = error.email;
            // The AuthCredential type that was used.
            const credential = GoogleAuthProvider.credentialFromError(error);
            // ...
        });
    });

    const googleSignOut = () => {
            signOut(auth).then(() => {
                // Sign-out successful.
                setUser(null)
                console.log("Sign Out Was Successful");
            }).catch((error) => {
                // An error happened.
                console.log(error.code);
                console.log(error.message);
            });
    }

    const Home = ({ user }) => {
        return (
          <div className="home">
            <h1>Hello, <span></span>{user.displayName}</h1>
            <img src={user.photoURL} alt="" />
            <button className="button signout" onClick={() => auth.signOut()}>Sign out</button>
          </div>
        )
    }





    return (
      <>
        <ScrollToTopOnMount />
        {/* Hero Banner */}
        <section className="sectionLarge topSection">
            <hr/>
             <h1> Authentication Page </h1>
             <div>
                {user ? 
                <>
                    <h2> {user.displayName} </h2>
                    <h3> {user.email} </h3>
                    <h3> {user.uid} </h3>
                    {/* <button className="button" onClick={signInRedirect}>Sign in with google</button> */}
                    <button className="button signout" onClick={googleSignOut}>Sign out</button>
                </>
                : 
                <>
                    <h1> There is NOT a User</h1>
                    <button className="button" onClick={signInRedirect}>Sign in with google</button>
                </>
                }
            </div>
            <hr/>
        </section>

        {/* Footer */}
        <section className="sectionSmall themebackground overlayText">
          <h1>Connect With Us</h1>
          <div className="containerFlex">
            <div className="flex-item">
              <h3>Contact Us</h3>
              <p>Email: kelaiah.care@gmail.com</p>
            </div>
            <div className="flex-item">
              <h3>Our Pages</h3>
              <p><Link className="sideNavLink" to="/services">Services</Link></p>
              <p><Link className="sideNavLink" to="/about">About Us</Link></p>
              <p><Link className="sideNavLink" to="/contact">Contact Us</Link></p>
              <p>log-in</p>
            </div>
            <div className="flex-item">
              <h3>Hours Of Operation</h3>
              <p>Mon - Fri: 9:00AM - 6:00PM</p>
              <p>Sat: 10:00AM - 5:00PM</p>
              <p>Contact Us Any Time</p>
            </div>
          </div>
        </section>
      </>
    );
}

export default Auth;