import * as React from "react";
import './home.css';

import {Link} from "react-router-dom";
import { useEffect } from "react";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}


function Home() {
    return (
      <>
        <ScrollToTopOnMount />
        {/* Banner With Image */}
        <section className="hero-image overlayText sectionLarge topSection">
          <hr/>
          <h1>Kelaiah; Voice of the Lord</h1>
          <span><h3>We believe that it is possible to bring the voice of god into everyones life. Whether you are a believer or not, we still believe that we can help you find spiritual stability with Jesus Christ</h3></span>
          <button className="button-overlay">Support Us</button>
          <hr/>
        </section>

        {/* Section Divider */}
        {/* <div class="custom-shape-divider-top-1637775136" style={{backgroundColor: 'lightgray'}}>
              <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                  <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
              </svg>
        </div> */}

        {/* About Us Section */}
        <section className="sectionLarge">
          <div className="containerFlex">
            <div className="flex-item">
              <h2>We Care About Change</h2>
              <span><p>Depression and anxiety is a leading disorder in many countries. Depression can be seasonal or it can be long term having lasting effects on your body. Our mission is simple, at Kelaiah we try to help people find tools to help themselves with their depression and anxiety. We believe that the bible has many answers, comparative stories and has many lessons to be learned. Our goal is to make the bible interactive in everyone's daily lives.</p></span>
              <button className="button-main"><Link className="sideNavLink" to="/about">Learn More About Us</Link></button>
            </div>
          </div>
        </section>
        
        {/* 3 Reasons */}
        <section className="sectionLarge" style={{backgroundColor:'white'}}>
          <h3>EXPERENCE QUALITY AND SUPPORT CHANGE</h3>
          <h2>Quality Products That Make A Difference</h2>
          <div className="containerFlex">
            <div className="flex-item">
              <img style={{objectFit: 'cover'}} src="https://images.unsplash.com/photo-1582736317407-371893d9e146?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=764&q=80" alt="Coding on a screen" width="100%" height="300px"/>
              <h4>Web Application Software</h4>
              <span><p>Here at Kelaiah we pride ourselves in web applications. We not only believe that web applications are important but are also to reach many people without requiring a special downloadable application. We sell our components individually as well as multi-component applications. Learn More </p></span>
              <button className="button-main"><Link className="sideNavLink" to="/services">Services</Link></button>
            </div>
            <div className="flex-item">
              <img style={{objectFit: 'cover'}} src="https://images.unsplash.com/photo-1499209974431-9dddcece7f88?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80" alt="Praise and worship" width="100%" height="300px"/>
              <h4>Spiritual Growth Blogs</h4>
              <span><p>Here at Kelaiah we pride ourselves in web applications. We not only believe that web applications are important but are also to reach many people without requiring a special downloadable application. We sell our components individually as well as multi-component applications. Learn More </p></span>
              <button className="button-main"><Link className="sideNavLink" to="/services">Coming Soon</Link></button>
            </div>
            <div className="flex-item">
              <img style={{objectFit: 'cover'}} src="https://images.unsplash.com/photo-1562157873-818bc0726f68?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=627&q=80" alt="T-Shirt Line" width="100%" height="300px"/>
              <h4>Brand Products And Merchandise</h4>
              <span><p>Here at Kelaiah we pride ourselves in web applications. We not only believe that web applications are important but are also to reach many people without requiring a special downloadable application. We sell our components individually as well as multi-component applications. Learn More </p></span>
              <button className="button-main"><Link className="sideNavLink" to="/services">Coming Soon</Link></button>
            </div>
          </div>
        </section>
        
        {/* Call To Action */}
        <section className="sectionLarge">
          <div className="container text-center">
              <h1>Contact Us Today</h1>
              <h3>Want to help us achieve our goals.</h3>
              <button className="button-main"><Link className="sideNavLink" to="/contact">Message Us</Link></button>
          </div>
        </section>

        {/* Footer */}
        <section className="sectionSmall themebackground overlayText">
        <hr/>
          <h1>Connect With Us</h1>
          <div className="containerFlex">
            <div className="flex-item">
              <h3>Contact Us</h3>
              <p>Email: kelaiah.care@gmail.com</p>
            </div>
            <div className="flex-item">
              <h3>Our Pages</h3>
              <p><Link className="sideNavLink" to="/services">Services</Link></p>
              <p><Link className="sideNavLink" to="/about">About Us</Link></p>
              <p><Link className="sideNavLink" to="/contact">Contact Us</Link></p>
              <p>log-in</p>
            </div>
            <div className="flex-item">
              <h3>Hours Of Operation</h3>
              <p>Mon - Fri: 9:00AM - 6:00PM</p>
              <p>Sat: 10:00AM - 5:00PM</p>
              <p>Contact Us Any Time</p>
            </div>
          </div>
        <hr/>
        </section>
      </>
    );
}

export default Home;