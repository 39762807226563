
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "@firebase/firestore";

    const firebaseConfig = {
        apiKey: "AIzaSyA1nDsiDOkakzGuqVbUNgL5XQ0h6PGMFDU",
        authDomain: "kelaiah-care.firebaseapp.com",
        projectId: "kelaiah-care",
        storageBucket: "kelaiah-care.appspot.com",
        messagingSenderId: "291774989967",
        appId: "1:291774989967:web:5cf88078a5c94e283652fa",
        measurementId: "G-KLM48H7SK0"
      };
    
    // Initialize Firebase
    const firebase = initializeApp(firebaseConfig);

    // Analytics
    export const analytics = getAnalytics(firebase);

    // Database
    export const db = getFirestore(firebase);

    // Authentication
  

    // export const auth = firebase.auth();

    // const provider = new firebase.auth.GoogleAuthProvider();
    // provider.setCustomParameters({ prompt: 'select_account' });

    // export const signInWithGoogle = () => auth.signInWithPopup(provider);

    export default firebase;
